/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


window.Vue = require('vue').default;

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import Vue from 'vue';
import VeeValidate from 'vee-validate';


import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';


Vue.use(VueToast);

Vue.use(VeeValidate);

import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import Card1 from './components/Card1'
import Card2 from './components/Card2'
import Card3 from './components/Card3'
import Card4 from './components/Card4'
import Card5 from './components/Card5'

const routes = [
    { path: '/', component: Card1 },
    { path: '/form2/:id', component: Card2 },
    { path: '/form3/:id', component: Card3 },
    { path: '/form4/:id', component: Card4 },
    {path:"/form5/:id",component:Card5}
]

const router = new VueRouter({
    mode: 'history',
    routes
})


Vue.component('header-container', require('./components/Header.vue').default);
Vue.component('main-container', require('./components/MainContainer.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router
});
