<template>
    <div style="display:flex;" class="price-area">
        <p>
            Ativação:R$ {{ativacao}}
        </p>
        <p style="color:white;">
            Mensalidade:R$ {{ mensalidade }}
        </p>
    </div>
</template>


<script>
export default {
    props:['ativacao','mensalidade'],
    data(){
        return {
              
        }
    },
}
</script>

<style scoped>

    p{
        color:white !important;
        padding: 3px;
        margin: 8px;
    }

    .price-area{
        position:fixed;
        bottom: 0;
        right: 20%;
        background: #3098ae;
        z-index: 9999;
        padding: 10px 20px !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    @media(max-width:600px){
        .price-area{
            bottom: 90%;
            top: 0;
            border-radius: 5px;
        }
    }
</style>