var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mask)?_c('div',[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.data)?_vm._i(_vm.data,null)>-1:(_vm.data)},on:{"change":[function($event){var $$a=_vm.data,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.data=$$a.concat([$$v]))}else{$$i>-1&&(_vm.data=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.data=$$c}},_vm.changeData]}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.data,null)},on:{"change":[function($event){_vm.data=null},_vm.changeData]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.data)},on:{"change":_vm.changeData,"input":function($event){if($event.target.composing){ return; }_vm.data=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Campo obrigatório")])]):_vm._e(),_vm._v(" "),(!_vm.mask)?_c('div',[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}},{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.data)?_vm._i(_vm.data,null)>-1:(_vm.data)},on:{"change":[function($event){var $$a=_vm.data,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.data=$$a.concat([$$v]))}else{$$i>-1&&(_vm.data=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.data=$$c}},_vm.changeData]}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}},{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.data,null)},on:{"change":[function($event){_vm.data=null},_vm.changeData]}}):_c('input',{directives:[{name:"validate",rawName:"v-validate.initial",value:(_vm.validation),expression:"validation",modifiers:{"initial":true}},{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:"input",class:[
        {
          'form-control': true,
          'is-invalid': (_vm.errors.has(_vm.name) && _vm.isFormDirty) || (_vm.error && !_vm.isFormDirty),
        },
        { 'is-valid': !_vm.errors.has(_vm.name) && _vm.isFormDirty } ],attrs:{"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.data)},on:{"change":_vm.changeData,"input":function($event){if($event.target.composing){ return; }_vm.data=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Campo obrigatório")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }