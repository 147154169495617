<template>
  <div
    style="
      text-align: left;
      font-size: 1em;
      padding-top: 10px;
      padding-bottom: 20px;
      color: #25215e;
    "
    class="col-md-12"
  >
    <span
      >Trata-se do aplicativo que será utilizado em dispositivos como celulares,
      notebooks ou desktops. A sua contratação não é obrigatória, podendo o
      cliente escolher uma solução de sua preferência. Caso haja alguma dúvida
      quanto a essas soluções, entre em contato conosco através do número 11
      3534-7000 – opção 1.</span
    >
  </div>
</template>


<script>
export default {
    
};
</script>