<template>
  <div>
    <div class="col-md-12 mx-auto mb-3">
      <form @submit.prevent="avancarPagina">
        <div class="card">
          <div class="col-md-12 mb-5 mt-3">
            <div class="card-body">
              <div class="form-row">
                <div
                  style="
                    text-align: left;
                    font-size: 1.5em;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    color: #25215e;
                  "
                  class="col-md-12"
                >
                  <span
                    >Obrigado! Agora vamos entender o que você precisa.</span
                  >
                </div>

                <div class="col-md-12 mt-1">
                  <span
                    ><strong
                      >Você já possui uma linha telefônica atualmente?</strong
                    ></span
                  >
                </div>

                <div class="col-md-12 mt-1">
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="possuiLinhaTelefonica"
                      v-validate.initial="'required'"
                      @change="mudarLinhaTelefonica"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="0"
                    />
                    <label class="form-check-label" for="inlineRadio1"
                      >Sim</label
                    >
                  </div>
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="mudarLinhaTelefonica"
                      v-model="possuiLinhaTelefonica"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="1"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Não</label
                    >
                  </div>
                </div>

                <div class="col-md-12 mt-1" v-if="possuiLinhaTelefonica == '0'">
                  <span
                    ><strong
                      >Deseja fazer a portabilidade deste, ou mais números, para
                      a Sothis Telecom?</strong
                    ></span
                  >
                </div>

                <div class="col-md-12 mt-1" v-if="possuiLinhaTelefonica == '0'">
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-validate.initial="'required'"
                      @change="mudarPortabilidade"
                      v-model="deseja_fazer_portabilidade"
                      name="portabilidade"
                      id="portabilidade"
                      value="0"
                    />
                    <label class="form-check-label" for="portabilidade"
                      >Sim</label
                    >
                  </div>
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="mudarPortabilidade"
                      v-model="deseja_fazer_portabilidade"
                      name="portabilidade2"
                      id="portabilidade2"
                      value="1"
                    />
                    <label class="form-check-label" for="portabilidade2"
                      >Não</label
                    >
                  </div>
                </div>

                <div
                  class="col-md-12 mt-1"
                  v-if="deseja_fazer_portabilidade == '1'"
                >
                  <span
                    ><strong
                      >Certo, entendemos que você não deseja fazer a
                      portabilidade da sua linha telefônica atual. Você deseja
                      adquirir uma Linha Sothis Telecom para uso corporativo e
                      para a configuração de eventuais ramais em nuvem?</strong
                    ></span
                  >
                </div>

                <div
                  class="col-md-12 mt-1"
                  v-if="deseja_fazer_portabilidade == '1'"
                >
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="mudarAdquirirLinhaSothis"
                      v-validate.initial="'required'"
                      v-model="deseja_adquirir_linha_sothis"
                      name="deseja_adquirir_linha_sothis"
                      id="deseja_adquirir_linha_sothis"
                      value="0"
                    />
                    <label
                      @change="mudarAdquirirLinhaSothis"
                      class="form-check-label"
                      for="deseja_adquirir_linha_sothis"
                      >Sim</label
                    >
                  </div>
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="mudarAdquirirLinhaSothis"
                      v-model="deseja_adquirir_linha_sothis"
                      name="deseja_adquirir_linha_sothis2"
                      id="deseja_adquirir_linha_sothis2"
                      value="1"
                    />
                    <label
                      class="form-check-label"
                      for="deseja_adquirir_linha_sothis2"
                      >Não</label
                    >
                  </div>
                </div>

                <div
                  class="col-md-12 mt-1"
                  v-if="deseja_adquirir_linha_sothis == '1'"
                >
                  <span
                    ><strong
                      >Bom, neste caso, em que você optou por manter a sua linha
                      telefônica atual e não deseja fazer a portabilidade, temos
                      a opção de realizar a instalação de um servidor PABX IP e
                      a ativação de ramais no endereço de seu escritório. Deseja
                      solicitar a instalação de um servidor PABX IP, bem como a
                      ativação e configuração de ramais?</strong
                    ></span
                  >
                </div>

                <div
                  class="col-md-12 mt-1"
                  v-if="deseja_adquirir_linha_sothis == '1'"
                >
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-validate.initial="'required'"
                      v-model="instalar_servidor_pabx"
                      @change="mudarServidorPabx"
                      name="instalar_servidor_pabx"
                      id="instalar_servidor_pabx"
                      value="0"
                    />
                    <label class="form-check-label" for="instalar_servidor_pabx"
                      >Sim</label
                    >
                  </div>
                  <div class="form-check form-check-inline mt-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="mudarServidorPabx"
                      v-model="instalar_servidor_pabx"
                      name="instalar_servidor_pabx2"
                      id="instalar_servidor_pabx2"
                      value="1"
                    />
                    <label
                      class="form-check-label"
                      for="instalar_servidor_pabx2"
                      >Não</label
                    >
                  </div>
                </div>

                <div
                  class="col-md-12 mx-auto"
                  v-if="
                    instalar_servidor_pabx == '0' &&
                    deseja_adquirir_linha_sothis == '1' &&
                    deseja_fazer_portabilidade == '1'
                  "
                >
                  <div>
                    <div class="alert alert-primary" role="alert">
                      1 Servidor Físico PABX IP - Sothis Valor único de
                      ativação:R$ 680,00<br />
                      Valor mensal:R$ 680,00
                    </div>
                    <div class="mt-3 col-md-4">
                      <label for=""
                        >Digite a quantidade de servidores Pabx</label
                      >
                      <input
                        type="number"
                        name="servidores_pabx"
                        min="1"
                        required
                        v-model="servidores_pabx"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  class="mt-5 mx-auto text-center"
                  v-if="
                    possuiLinhaTelefonica == '0' &&
                    deseja_fazer_portabilidade == '1' &&
                    deseja_adquirir_linha_sothis == '1' &&
                    instalar_servidor_pabx == '1'
                  "
                >
                  <p>
                    Precisamos entender melhor a sua necessidade, um de nossos
                    consultores pode entrar em contato com você ou sua equipe.
                  </p>
                  <a
                    @click="solicitarContato"
                    class="btn btn-block btn-primary text-white"
                  >
                    Solicitar Contato
                  </a>
                </div>

                <a
                  class="btn btn-secondary btn-sm text-white mb-2"
                  @click="incrementar"
                  v-if="
                    possuiLinhaTelefonica == '1' ||
                    deseja_adquirir_linha_sothis == '0'
                  "
                >
                  Adicionar
                </a>

                <a
                  class="btn btn-secondary btn-sm text-white ml-5 mb-2"
                  @click="decrementar"
                  v-if="
                    possuiLinhaTelefonica == '1' ||
                    deseja_adquirir_linha_sothis == '0'
                  "
                >
                  Remover
                </a>

                <div
                  v-for="(x, indice) in i"
                  :key="indice"
                  class="col-md-12 row"
                >
                  <div
                    class="col-md-4"
                    v-if="
                      possuiLinhaTelefonica == 1 ||
                      deseja_adquirir_linha_sothis == '0'
                    "
                  >
                    <div class="input-group mb-3 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Linha {{ x }}</span
                        >
                      </div>
                      <input
                        required
                        type="number"
                        disabled
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    v-if="
                      possuiLinhaTelefonica == 1 ||
                      deseja_adquirir_linha_sothis == '0'
                    "
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Atendimentos Simultâneos Adicional</span
                        >
                      </div>
                      <input
                        required
                        type="number"
                        min="0"
                        v-model="atendimentos_simultaneos[indice]"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div
                    class="col-md-2"
                    v-if="
                      possuiLinhaTelefonica == 1 ||
                      deseja_adquirir_linha_sothis == '0'
                    "
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >DDD</span
                        >
                      </div>
                      <input
                        required
                        min="1"
                        type="number"
                        v-model="dd[indice]"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <a
                  class="btn btn-secondary btn-sm text-white mb-2"
                  @click="incrementarPortabilidade"
                  v-if="deseja_fazer_portabilidade == '0'"
                >
                  Adicionar
                </a>

                <a
                  class="btn btn-secondary btn-sm text-white ml-5 mb-2"
                  @click="decrementarPortabilidade"
                  v-if="deseja_fazer_portabilidade == '0'"
                >
                  Remover
                </a>

                <div
                  v-for="(k, indice) in j"
                  :key="k + new Date().getTime()"
                  class="col-md-12 row"
                >
                  <div
                    class="col-md-4"
                    v-if="deseja_fazer_portabilidade == '0'"
                  >
                    <div class="input-group mb-3 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Linha {{ k }}</span
                        >
                      </div>
                      <input
                        required
                        type="number"
                        disabled
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    v-if="deseja_fazer_portabilidade == '0'"
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Atendimentos Simultâneos</span
                        >
                      </div>
                      <input
                        @change="
                          adicionarAtendimentosSimultaneosPortabilidade(
                            indice,
                            $event
                          )
                        "
                        required
                        type="number"
                        min="1"
                        :value="atendimentos_simultaneos_portabilidade[indice]"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="deseja_fazer_portabilidade == '0'"
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Operadora</span
                        >
                      </div>
                      <input
                        required
                        @change="
                          adicionarOperadoraPortabilidade(indice, $event)
                        "
                        type="text"
                        :value="operadora_portabilidade[indice]"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="deseja_fazer_portabilidade == '0'"
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"
                          >Numero</span
                        >
                      </div>
                      <input
                        required
                        type="text"
                        pattern="^(\([0-9]{2}\))\s([9]{1})?([0-9]{4})-([0-9]{4})$"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        @change="adicionarPortabilidade(indice, $event)"
                        :value="numero_portabilidade[indice]"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div
                    class="input-group mb-3"
                    v-if="
                      deseja_adquirir_linha_sothis == '0' ||
                      deseja_fazer_portabilidade == '0' ||
                      possuiLinhaTelefonica == '1' ||
                      deseja_adquirir_linha_sothis == '0'
                    "
                  >
                    <div class="col-md-12" v-if="i > 0 || j > 0">
                      <strong
                        >Digite a quantidade total de ramais (Nossos ramais são
                        digitais e funcionam em nuvem).</strong
                      >
                    </div>
                    <div class="col-md-4" v-if="i > 0 || j > 0">
                      <input
                        required
                        min="0"
                        type="number"
                        v-model="numero_ramais"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <button
              :disabled="
                (deseja_fazer_portabilidade == '0' && j == 0) ||
                (possuiLinhaTelefonica == '1' && i == 0) ||
                (deseja_adquirir_linha_sothis == '0' && i == '0') ||
                (possuiLinhaTelefonica == '0' &&
                  deseja_fazer_portabilidade == '1' &&
                  deseja_adquirir_linha_sothis == '1' &&
                  instalar_servidor_pabx == '1')
              "
              class="btn btn-primary"
              ref="button"
              type="submit"
            >
              Avançar
            </button>
          </div>
        </div>
      </form>
      <CustomAlert :ativacao="getAtivacao" :mensalidade="getMensalidade" />
    </div>
  </div>
</template>

<script>
import Hashids from "hashids";
const hashids = new Hashids();
import CustomAlert from "./CustomAlert";

export default {
  mounted() {
    this.id = hashids.decode(this.$route.params.id)[0];
  },
  data() {
    return {
      id: "",
      numero_linhas: "",
      atendimentos_simultaneos: [],
      possuiLinhaTelefonica: "",
      numero_ramais: "",
      numero_portabilidade: [],
      atendimentos_simultaneos_portabilidade: [],
      operadora_portabilidade: [],
      servidores_pabx: 0,
      dd: [],
      instalar_servidor_pabx: "",
      deseja_adquirir_linha_sothis: "",
      deseja_fazer_portabilidade: "",
      i: 0,
      j: 0,
    };
  },
  components: {
    CustomAlert,
  },
  computed: {
    getAtivacao() {
      let ativacao = 0;
      for (let x in this.atendimentos_simultaneos_portabilidade) {
        ativacao +=
          (this.atendimentos_simultaneos_portabilidade[x] == undefined
            ? 0
            : this.atendimentos_simultaneos_portabilidade[x]) * 35;
      }
      let ativacao2 = 0;
      for (let x in this.atendimentos_simultaneos) {
        ativacao2 +=
          (this.atendimentos_simultaneos[x] == undefined
            ? 0
            : this.atendimentos_simultaneos[x]) * 35;
      }
      let ramais = this.numero_ramais * 35;
      let servidores_pabx = this.servidores_pabx * 680;
      return (
        35 * this.j +
        ativacao +
        this.i * 35 +
        ativacao2 +
        ramais +
        servidores_pabx
      ).toFixed(2);
    },
    getMensalidade() {
      let ativacao = 0;
      for (let x in this.atendimentos_simultaneos_portabilidade) {
        ativacao +=
          (this.atendimentos_simultaneos_portabilidade[x] == undefined
            ? 0
            : this.atendimentos_simultaneos_portabilidade[x]) * 30;
      }
      let ativacao2 = 0;
      for (let x in this.atendimentos_simultaneos) {
        ativacao2 +=
          (this.atendimentos_simultaneos[x] == undefined
            ? 0
            : this.atendimentos_simultaneos[x]) * 30;
      }
      let ramais = this.numero_ramais * 30;
      let servidores_pabx = this.servidores_pabx * 680;

      return (
        30 * this.j +
        ativacao +
        this.i * 30 +
        ativacao2 +
        ramais +
        servidores_pabx
      ).toFixed(2);
    },
  },
  methods: {
    incrementar() {
      this.i = this.i + 1;
      if(this.possuiLinhaTelefonica == '1' || this.deseja_adquirir_linha_sothis == '0'){
        this.atendimentos_simultaneos.splice(this.i,0,0);
      }
    },
    decrementar() {
      if (this.i > 1) {
        this.i = this.i - 1;
      }
    },
    incrementarPortabilidade() {
      this.j = this.j + 1;
    },
    decrementarPortabilidade() {
      if (this.j > 1) {
        this.j = this.j - 1;
      }
    },
    mudarLinhaTelefonica() {
      if (this.possuiLinhaTelefonica == 1) {
        this.i = 1;
        this.instalar_servidor_pabx = "";
        this.deseja_adquirir_linha_sothis = "";
        this.deseja_fazer_portabilidade = "";
      }
      this.dd = [];
      this.i = 0;
      this.atendimentos_simultaneos = [];
      this.numero_ramais = 0;
    },
    async solicitarContato() {
      let response = await axios.post("/credenciado/solicitar/contato", {
        id: this.id,
      });
      if (response.status == 200) {
        Vue.$toast.open({
          message: "Certo! Em breve, um de nossos consultores entrará em contato com você!",
          type: "success",
          position: "bottom-left",
        });
        setTimeout(() => {
          window.location.href = "https://sothis.com.br/"
        },3000);
      }else if(response.status == 400){
        Vue.$toast.open({
          message: "Ocorreu um erro, entre em contato com a equipe de TI",
          type: "danger",
          position: "bottom-right",
        });
      }
    },
    mudarPortabilidade(event) {
      this.instalar_servidor_pabx = "";
      this.deseja_adquirir_linha_sothis = "";
      this.atendimentos_simultaneos_portabilidade = [];
      this.operadora_portabilidade = [];
      this.numero_portabilidade = [];
      this.j = 0;
      this.numero_ramais = 0;
    },
    mudarServidorPabx() {
      this.servidores_pabx = 0;
    },
    mudarAdquirirLinhaSothis() {
      this.instalar_servidor_pabx = "";
      this.atendimentos_simultaneos = [];
      this.dd = [];
      this.i = 0;
    },
    adicionarPortabilidade(indice, event) {
      this.numero_portabilidade.splice(indice, 1, event.target.value);
    },
    adicionarOperadoraPortabilidade(indice, event) {
      this.operadora_portabilidade.splice(indice, 1, event.target.value);
    },
    adicionarAtendimentosSimultaneosPortabilidade(indice, event) {
      this.atendimentos_simultaneos_portabilidade.splice(
        indice,
        1,
        event.target.value
      );
    },
    async avancarPagina() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$refs.button.disabled = true;
          let response = await axios.post("/credenciado/formulario2", {
            servidores_pabx: this.servidores_pabx,
            ativacao: this.getAtivacao,
            mensalidade: this.getMensalidade,
            dd: this.dd,
            atendimentos_simultaneos: this.atendimentos_simultaneos,
            instalar_servidor_pabx:
              this.instalar_servidor_pabx == ""
                ? 1
                : this.instalar_servidor_pabx,
            deseja_adquirir_linha_sothis:
              this.deseja_adquirir_linha_sothis == ""
                ? 1
                : this.deseja_adquirir_linha_sothis,
            possui_linha_telefonica:
              this.possuiLinhaTelefonica == "" ? 1 : this.possuiLinhaTelefonica,
            deseja_fazer_portabilidade:
              this.deseja_fazer_portabilidade == ""
                ? 1
                : this.deseja_fazer_portabilidade,
            servidores_pabx: this.servidores_pabx,
            credenciado_id: this.id,
            numero_ramais: this.numero_ramais,
            operadora_portabilidade: this.operadora_portabilidade,
            numero_portabilidade: this.numero_portabilidade,
            atendimentos_simultaneos_portabilidade: this
              .atendimentos_simultaneos_portabilidade,
          });

          if (response.status == 201) {
            const { id } = response.data;
            this.$router.push(`/form3/${hashids.encode(id)}`);
          }
        } else {
          Vue.$toast.open({
            message: "Algo deu errado, por favor selecione todas as opções",
            type: "error",
            position: "bottom-right",
          });
        }
      });
    },
  },
};
</script>