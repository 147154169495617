<template>
  <div>
      <Card1></Card1>
  </div>
</template>


<script>
import Card1 from "../components/Card1";
import Card2 from '../components/Card2';
import EventBus from '../EventBus'

export default {
    props:[],
    mounted(){
       
    },
    data(){
        return {
            selectedComponent:""
        }
    },
    components:{
        Card1,
        Card2
    }
};
</script>

<style scoped>
</style>