var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-md-12 mx-auto mb-3"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.finalizarPagina($event)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('Aviso2'),_vm._v(" "),_c('div',{staticClass:"col-md-12 mb-3"},_vm._l((_vm.filteredProdutos),function(produto,indice){return _c('ul',{key:produto.id,staticClass:"list-group"},[(produto.id != 6)?_c('li',{staticClass:"list-group-item"},[_vm._v("\n                  "+_vm._s(produto.nome)+" - "),_c('strong',[_vm._v("Valor Mensal:")]),_vm._v(" R$"+_vm._s(parseFloat(produto.valor_mensal).toFixed(2))+"\n                  "),_c('input',{attrs:{"type":"number","min":"0"},on:{"change":function($event){return _vm.adicionarMensal(
                        indice,
                        produto.valor_mensal,
                        produto.id,
                        $event
                      )}}})]):_vm._e()])}),0),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('Aviso2'),_vm._v(" "),_c('div',{staticClass:"col-md-12 mb-2"},_vm._l((_vm.filteredProdutos),function(produto,indice){return _c('ul',{key:produto.id,staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_vm._v("\n                  "+_vm._s(produto.nome)+" - "),_c('strong',[_vm._v("Valor Compra:")]),_vm._v(" R$"+_vm._s(parseFloat(produto.valor_compra).toFixed(2))+"\n                  "),_c('input',{attrs:{"type":"number","min":"0"},on:{"change":function($event){return _vm.adicionarCompra(
                        indice,
                        produto.valor_compra,
                        produto.id,
                        $event
                      )}}})])])}),0),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('Aviso'),_vm._v(" "),(_vm.produtos.length > 0)?_c('div',{staticClass:"col-md-12 mb-2"},[_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_vm._v("\n                  "+_vm._s(_vm.produtos[5].nome)+" - "),_c('strong',[_vm._v("Valor Mensal:")]),_vm._v(" R$"+_vm._s(parseFloat(_vm.produtos[5].valor_mensal).toFixed(2))+"\n                  "),_c('input',{attrs:{"type":"number","min":"0"},on:{"change":function($event){return _vm.adicionarMensal(
                        5,
                        _vm.produtos[5].valor_mensal,
                        _vm.produtos[5].id,
                        $event
                      )}}})])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mx-auto mt-3"},[_c('Tarifa')],1)],1)]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('button',{ref:"button",staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n            Avançar\n          ")])])])])]),_vm._v(" "),_c('CustomAlert',{attrs:{"ativacao":_vm.getTotalProdutosCompra,"mensalidade":_vm.getTotalProdutosMensal}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"left","font-size":"1.5em","padding-top":"20px","padding-bottom":"20px","color":"#25215e"}},[_c('span',[_vm._v("Por último, precisamos saber se você precisará de\n                equipamentos e serviços adicionais:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("\n                Segue abaixo uma lista com opções de compra e/ou locação. Os\n                valores estão sujeitos a alteração conforme disponibilidade\n                dos fornecedores.\n              ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"left","font-size":"1.5em","padding-top":"20px","padding-bottom":"20px","color":"#25215e"}},[_c('span',[_vm._v("Aparelho Telefônico – IP (Locação)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"left","font-size":"1.5em","padding-top":"20px","padding-bottom":"20px","color":"#25215e"}},[_c('span',[_vm._v("Aparelho Telefônico – IP (Compra)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"left","font-size":"1.5em","padding-top":"20px","padding-bottom":"20px","color":"#25215e"}},[_c('span',[_vm._v("SOFTPHONE")])])}]

export { render, staticRenderFns }