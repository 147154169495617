<template>
  <div>
    <div v-if="mask">
      <input
        v-model="data"
        v-mask="mask"
        ref="input"
        :disabled="disabled"
        @change="changeData"
        v-validate.initial="validation"
        :class="[
          {
            'form-control': true,
            'is-invalid': (errors.has(name) && isFormDirty) || (error && !isFormDirty),
          },
          { 'is-valid': !errors.has(name) && isFormDirty },
        ]"
        :name="name"
        :type="type"
        :placeholder="placeholder"
      />
      <div class="invalid-feedback">Campo obrigatório</div>
    </div>

    <div v-if="!mask">
      <input
        v-validate.initial="validation"
        v-model="data"
        ref="input"
        :disabled="disabled"
        @change="changeData"
        :class="[
          {
            'form-control': true,
            'is-invalid': (errors.has(name) && isFormDirty) || (error && !isFormDirty),
          },
          { 'is-valid': !errors.has(name) && isFormDirty },
        ]"
        :name="name"
        :type="type"
        :placeholder="placeholder"
      />
      <div class="invalid-feedback">Campo obrigatório</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "placeholder", "validation", "name", "mask","error","disabled"],
  data() {
    return {
      data: "",
      ref:""
    };
  },
  mounted() {},
  methods: {
    changeData() {
      this.$emit("change", this.data);
      this.$emit('emitirRef',this.$refs.input);
    },
  },
  computed: {
    isFormDirty() {
      return Object.keys(this.fields).some((key) => this.fields[key].dirty);
    },
  },
  watch: {
    inputChange: function (novo, velho) {
      this.data = novo;
    },
  },
};
</script>

<style scoped>
</style>