<template>
  <div class="col-md-12 mx-auto mb-3">
    <div class="card">
      <div class="col-md-12 mb-5 mt-3">
        <div class="form-row">
          <div class="col-md-12">
            <label for=""><strong>Razão Social</strong></label>
            <Input
              :error="erros.razao_social"
              name="razao_social"
              @change="razao_social = $event"
              ref="razao_ref"
              :validation="'required'"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>CNPJ:</strong></label>
            <Input
              :error="erros.cnpj"
              name="cnpj"
              :validation="{
                required: true,
                regex: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
              }"
              @change="cnpj = $event"
              :mask="'##.###.###/####-##'"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>Inscrição Estadual:</strong></label>
            <Input
              :error="erros.inscricao_estadual"
              name="inscricao_estadual"
              :validation="{
                required: false,
                regex: /[0-9]{9}/,
              }"
              @change="inscricao_estadual = $event"
              :mask="'#########'"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>Inscrição Municipal:</strong></label>
            <Input
              :error="erros.inscricao_municipal"
              name="inscricao_municipal"
              :validation="{
                required: false,
                regex: /[0-9]{11}/,
              }"
              @change="inscricao_municipal = $event"
              :mask="'###########'"
            />
          </div>
          <div class="col-md-12 mt-2">
            <label for=""><strong>Endereço Completo:</strong></label>
            <Input
              :error="erros.endereco_completo"
              name="endereco"
              @change="endereco_completo = $event"
              :validation="'required'"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label for=""><strong>Endereço instalação:</strong></label>
            <select v-model="instalacao" class="form-control">
              <option value="1">mesmo endereço</option>
              <option value="2">outro</option>
            </select>
          </div>

          <div class="col-md-6 mt-2" v-if="instalacao == '2'">
            <label for=""
              ><strong>Endereço de instalação Complementar:</strong></label
            >
            <Input
              :error="erros.endereco_instalacao"
              @change="endereco_instalacao = $event"
              name="endereco_instalacao"
              :validation="'required'"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for=""><strong>Endereço Cobrança:</strong></label>
            <select v-model="cobranca" class="form-control">
              <option value="1">mesmo endereço</option>
              <option value="2">outro</option>
            </select>
          </div>

          <div class="col-md-6 mt-2" v-if="cobranca == '2'">
            <label for=""
              ><strong>Endereço de Cobrança Complementar:</strong></label
            >
            <Input
              :error="erros.endereco_cobranca"
              @change="endereco_cobranca = $event"
              name="endereco_cobranca"
              :validation="'required'"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>Responsável Técnico:</strong></label>
            <Input
              :error="erros.responsavel_tecnico"
              name="responsavel_tecnico"
              :validation="'required'"
              @change="responsavel_tecnico = $event"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>E-mail resp. técnico:</strong></label>
            <Input
              :error="erros.email_responsavel_tecnico"
              name="email_responsavel_tecnico"
              :validation="'required|email'"
              @change="email_responsavel_tecnico = $event"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>Telefone resp. técnico:</strong></label>
            <Input
              :error="erros.telefone_responsavel_tecnico"
              name="telefone_responsavel_tecnico"
              :validation="{
                required: true,
                regex: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
              }"
              @change="telefone_responsavel_tecnico = $event"
              :mask="['(##) ####-####', '(##) #####-####']"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" ref="button" @click="avancarPagina" type="button">
          Avançar
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import Hashids from 'hashids'
const hashids = new Hashids()
import Input from "../components/Input";
import EventBus from '../EventBus'
export default {
  components: {
    Input,
  },
  data() {
    return {
      instalacao: 1,
      cobranca: 1,
      razao_social: "",
      cnpj: "",
      responsavel_tecnico: "",
      email_responsavel_tecnico: "",
      telefone_responsavel_tecnico: "",
      erros: {
        razao_social: false,
        cnpj: false,
        inscricao_estadual: false,
        inscricao_municipal: false,
        endereco_instalacao: false,
        endereco_cobranca: false,
        endereco_completo: false,
        responsavel_tecnico: false,
        email_responsavel_tecnico: false,
        telefone_responsavel_tecnico: false,
      },
      inscricao_estadual: "",
      inscricao_municipal: "",
      endereco_completo: "",
      endereco_instalacao: "",
      endereco_cobranca: "",
    };
  },
  computed: {},
  methods: {
    async avancarPagina() {
      console.log(!this.inscricao_estadual);
      if (!this.razao_social) {
        this.erros.razao_social = true;
      } else {
        this.erros.razao_social = false;
      }
      if (
        !this.cnpj ||
        !this.cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/)
      ) {
        this.erros.cnpj = true;
      } else {
        this.erros.cnpj = false;
      }
      if (this.inscricao_estadual != '' && this.inscricao_estadual.match(/[0-9]{9}/)) {
        this.erros.inscricao_estadual = true;
      } else {
        this.erros.inscricao_estadual = false;
      }
      if (this.inscricao_estadual != '' && this.inscricao_estadual.match(/[0-9]{11}/)) {
        this.erros.inscricao_municipal = true;
      } else {
        this.erros.inscricao_municipal = false;
      }
      if (!this.endereco_completo) {
        this.erros.endereco_completo = true;
      } else {
        this.erros.endereco_completo = false;
      }
      if (this.instalacao == 2) {
        if (!this.endereco_instalacao) {
          this.erros.endereco_instalacao = true;
        } else {
          this.erros.endereco_instalacao = false;
        }
      } else {
        this.erros.endereco_instalacao = false;
      }
      if (this.cobranca == 2) {
        if (!this.endereco_cobranca) {
          this.erros.endereco_cobranca = true;
        } else {
          this.erros.endereco_cobranca = false;
        }
      } else {
        this.erros.endereco_cobranca = false;
      }

      if (!this.responsavel_tecnico) {
        this.erros.responsavel_tecnico = true;
      } else {
        this.erros.responsavel_tecnico = false;
      }

      if (!this.email_responsavel_tecnico) {
        this.erros.email_responsavel_tecnico = true;
      } else {
        this.erros.email_responsavel_tecnico = false;
      }

      if (!this.telefone_responsavel_tecnico) {
        this.erros.telefone_responsavel_tecnico = true;
      } else {
        this.erros.telefone_responsavel_tecnico = false;
      }

      if (!this.telefone_responsavel_tecnico || !this.telefone_responsavel_tecnico.match(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/)) {
        this.erros.telefone_responsavel_tecnico = true;
      } else {
        this.erros.telefone_responsavel_tecnico = false;
      }

      if (
        !this.erros.razao_social &&
        !this.erros.cnpj &&
        !this.erros.endereco_completo &&
        !this.erros.endereco_instalacao &&
        !this.erros.endereco_cobranca &&
        !this.erros.responsavel_tecnico &&
        !this.erros.email_responsavel_tecnico &&
        !this.erros.telefone_responsavel_tecnico
      ) {
        this.$refs.button.disabled = true;
        let response = await axios.post("/credenciado/formulario1", {
          razao_social: this.razao_social,
          cnpj: this.cnpj,
          inscricao_estadual: this.inscricao_estadual,
          inscricao_municipal: this.inscricao_municipal,
          endereco_instalacao: this.endereco_instalacao,
          endereco_completo: this.endereco_completo,
          endereco_cobranca: this.endereco_cobranca,
          responsavel_tecnico: this.responsavel_tecnico,
          email_responsavel_tecnico: this.email_responsavel_tecnico,
          telefone_responsavel_tecnico: this.telefone_responsavel_tecnico,
        });
        console.log(response.data)
        this.$router.push(`/form2/${hashids.encode(response.data.id)}`);
      }
    },
  },
};
</script>

<style scoped>
</style>