<template>
  <div class="col-md-12 mx-auto mb-3">
    <div class="card">
      <div class="col-md-12 mb-5 mt-3">
        <div
          style="
            text-align: left;
            font-size: 1.5em;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #25215e;
          "
          class="col-md-12"
        >
          <span
            >Obrigado! Sua solicitação foi processada e havendo necessidade um
            de nossos consultores entrará em contato.</span
          >
        </div>
        <div class="col-md-12">
          <p>
            Você já pode fechar essa página. Para esclarecer qualquer dúvida
            você pode entrar em contato através dos telefones: (11) 3534-7000
            para São Paulo, também no (21) 3883-7000 para Rio de Janeiro e no
            0800 020 1337 para demais localidades.
          </p>
        </div>
        <div class="col-md-12">
          <div class="alert alert-primary" role="alert">
            <div
              style="
                text-align: center;
                font-size: 1.5em;
                padding-top: 20px;
                padding-bottom: 20px;
                color: #25215e;
              "
              class="col-md-12"
            >
              <span class="text-center"
                >Sua conexão de Internet é rápida e estável?</span
              >
            </div>
            <p class="text-center">
              Com o <strong>Link Dedicado Sothis</strong>, sua conexão será
              ultra-rápida e com estabilidade garantida! Caso tenha interesse
              podemos entrar em contato com você. <br />
            </p>
            <div class="mx-auto d-flex justify-content-center">
              <a
                @click="solicitarContato"
                class="btn btn-primary mx-auto text-white"
                >Solicitar Contato</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  mounted() {
    this.id = hashids.decode(this.$route.params.id)[0];
  },
  methods: {
      async solicitarContato() {
      let response = await axios.post("/credenciado/solicitar/internet", {
        id: this.id,
      });
      if (response.status == 200) {
        Vue.$toast.open({
          message: "Certo! Em breve, um de nossos consultores entrará em contato com você!",
          type: "success",
          position: "bottom-left",
        });
        setTimeout(() => {
          window.location.href = "https://sothis.com.br/"
        },3000);
      }else if(response.status == 400){
        Vue.$toast.open({
          message: "Ocorreu um erro, entre em contato com a equipe de TI",
          type: "danger",
          position: "bottom-right",
        });
      }
    },
  },
};
</script>