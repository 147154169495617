<template>
  <div>
    <div>
      <img
        :src="image"
      />
    </div>
    <div class="col-md-6 mx-auto">
    <p class="text-center" id="header-title">
      <span style="font-size: 14pt">
        <strong>
          <span style="color: #25215e"
            >Formulário de aquisição de serviços de telefonia Sothis Telecom</span
          >
        </strong>
      </span>
    </p>
    <p style="text-align: center">
      <span style="color: #777777"
        >Este formulário é destinado apenas aos agentes de investimentos
        credenciados do <strong>Banco Safra</strong>, para outras situações por
        favor fale conosco através da aba contato. Preencha corretamente todos
        os campos abaixo, caso necessário, um de nossos representantes
        comerciais entrarão em contato com você.</span
      >
    </p>
    </div>
  </div>
</template>


<script>
  import header from '../../images/header.jpg'
export default {
    data(){
      return {
        image:header
      }
    }
};
</script>

<style scoped>
#header-title {
  margin-top: 20px;
}

img {
  width: 100%;
  height: 38vh;
}
</style>