<template>
  <div>
    <table style="width: 1202.22px">
      <tbody>
        <tr>
          <td colspan="4">
            <strong
              >Confira os valores das tarifas por minuto de chamada:</strong
            >
          </td>
        </tr>
        <tr>
          <td>Chamada Local para Fixo</td>
          <td>0,05</td>
          <td>Chamada Local para M&oacute;vel:</td>
          <td>0,35</td>
        </tr>
        <tr>
          <td>Chamada Intra-Estadual para Fixo</td>
          <td>0,10</td>
          <td>Chamada Intra-Estadual para M&oacute;vel</td>
          <td>0,71</td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {};
</script>

<style scoped>
tr,
td {
  border: 1px solid black;
}
</style>