<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="form-row">
          <div
            style="
              text-align: left;
              font-size: 1.5em;
              padding-top: 20px;
              padding-bottom: 20px;
              color: #25215e;
            "
            class="col-md-12"
          >
            <span><strong>Confirme as informações preenchidas</strong></span>
          </div>

          <div class="col-md-12">
            <label for=""><strong>Razão Sócial</strong></label>
            <input
              class="form-control"
              v-model="aquisicao.credenciado.razao_social"
              :disabled="true"
              name="razao_social"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>CNPJ:</strong></label>
            <input
              class="form-control"
              v-model="aquisicao.credenciado.cnpj"
              :disabled="true"
              name="cnpj"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>Inscrição Estadual:</strong></label>
            <input
              class="form-control"
              :disabled="true"
              v-model="aquisicao.credenciado.inscricao_estadual"
              name="inscricao_estadual"
            />
          </div>
          <div class="col-md-4 mt-2">
            <label for=""><strong>Inscrição Municipal:</strong></label>
            <input
              :disabled="true"
              name="inscricao_municipal"
              v-model="aquisicao.credenciado.inscricao_municipal"
              class="form-control"
            />
          </div>
          <div class="col-md-12 mt-2">
            <label for=""><strong>Endereço Completo:</strong></label>
            <input
              class="form-control"
              :disabled="true"
              v-model="aquisicao.credenciado.endereco_completo"
              name="endereco"
              :validation="'required'"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for=""
              ><strong>Endereço de instalação Complementar:</strong></label
            >
            <input
              class="form-control"
              :disabled="true"
              v-model="aquisicao.credenciado.endereco_instalacao"
              name="endereco_instalacao"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label for=""
              ><strong>Endereço de Cobrança Complementar:</strong></label
            >
            <input
              v-model="aquisicao.credenciado.endereco_cobranca"
              class="form-control"
              :disabled="true"
              name="endereco_cobranca"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>Responsável Técnico:</strong></label>
            <input
              v-model="aquisicao.credenciado.responsavel_tecnico"
              class="form-control"
              :disabled="true"
              name="responsavel_tecnico"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>E-mail resp. técnico:</strong></label>
            <input
              v-model="aquisicao.credenciado.email_responsavel_tecnico"
              class="form-control"
              :disabled="true"
              name="email_responsavel_tecnico"
            />
          </div>

          <div class="col-md-4 mt-2">
            <label for=""><strong>Telefone resp. técnico:</strong></label>
            <input
              v-model="aquisicao.credenciado.telefone_responsavel_tecnico"
              class="form-control"
              :disabled="true"
              name="telefone_responsavel_tecnico"
            />
          </div>

          <div class="col-md-12" v-if="portabilidades.length > 0">
            <div
              style="
                text-align: center;
                font-size: 1.5em;
                padding-top: 20px;
                padding-bottom: 20px;
                color: #25215e;
              "
              class="col-md-12"
            >
              <span><strong>Confirme as Portabilidades</strong></span>
            </div>
          </div>
          <div
            v-for="portabilidade in portabilidades"
            :key="portabilidade.id"
            class="col-md-12 row mt-1"
          >
            <h2></h2>
            <div class="col-md-4">
              <div class="input-group mb-3 input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"></span>
                </div>
                <input
                  type="text"
                  disabled
                  v-model="portabilidade.linha"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><strong>Atendimentos Simultâneos</strong></span
                  >
                </div>
                <input
                  class="form-control"
                  v-model="portabilidade.atendimentos_simultaneos"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><strong>Operadora</strong></span
                  >
                </div>
                <input
                  disabled
                  v-model="portabilidade.operadora"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><strong>Numero</strong></span
                  >
                </div>
                <input
                  disabled
                  v-model="portabilidade.numero"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12" v-if="linhas_telefonicas.length > 0">
            <div
              style="
                text-align: center;
                font-size: 1.5em;
                padding-top: 20px;
                padding-bottom: 20px;
                color: #25215e;
              "
              class="col-md-12"
            >
              <span><strong>Confirme as Linhas Telefônicas</strong></span>
            </div>
          </div>

          <div
            v-for="linha in linhas_telefonicas"
            :key="linha.id"
            class="col-md-12 row mt-1"
          >
            <h2></h2>
            <div class="col-md-4">
              <div class="input-group mb-3 input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"></span>
                </div>
                <input
                  type="text"
                  disabled
                  v-model="linha.linha"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><strong>Atendimentos Simultâneos</strong></span
                  >
                </div>
                <input
                  class="form-control"
                  v-model="linha.atendimentos_simultaneos"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    ><strong>DDD</strong></span
                  >
                </div>
                <input disabled v-model="linha.dd" class="form-control" />
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-5">
            <ul
              class="list-group"
              v-for="adicional in servicos_adicionais"
              :key="adicional.id"
            >
              <li class="list-group-item">
                {{ adicional.produtos.nome }} -
                <strong>Valor {{ adicional.tipo }}:</strong> R${{
                  adicional.produtos[`valor_${adicional.tipo}`]
                }}
                -<strong>Quantidade:</strong> {{ adicional.quantidade }}
              </li>
            </ul>
          </div>

          <div class="col-md-12">
            <div
              style="
                text-align: center;
                font-size: 1.5em;
                padding-top: 20px;
                padding-bottom: 20px;
                color: #25215e;
              "
              class="col-md-12"
            >
              <span><strong>Confirmação dos serviços contratados</strong></span>
            </div>
            <div class="row">
              <div class="col-md-4 mt-2">
                <label for=""><strong>Numero Ramais</strong></label>
                <input
                  v-model="aquisicao.numero_ramais"
                  class="form-control"
                  :disabled="true"
                  name="telefone_responsavel_tecnico"
                />
              </div>

              <div class="col-md-4 mt-2">
                <label for=""><strong>Servidores PABX</strong></label>
                <input
                  v-model="aquisicao.servidores_pabx"
                  class="form-control"
                  :disabled="true"
                  name="telefone_responsavel_tecnico"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          @click="finalizarForm"
          class="btn btn-primary"
          ref="button"
          type="submit"
        >
          Finalizar
        </button>
      </div>
    </div>
    <CustomAlert :ativacao="ativacao" :mensalidade="mensalidade" />
  </div>
</template>

<script>
import Hashids from "hashids";
const hashids = new Hashids();
import CustomAlert from "./CustomAlert";

export default {
  async mounted() {
    this.id = hashids.decode(this.$route.params.id)[0];
    let response = await axios.get(`credenciado/getaquisicao/${this.id}`);
    let aquisicao = response.data;
    this.aquisicao = aquisicao;

    this.portabilidades = this.aquisicao.portabilidades;
    this.linhas_telefonicas = this.aquisicao.linha_telefonicas;
    this.servicos_adicionais = this.aquisicao.servicos_adicionais;

    this.ativacao = parseFloat(this.aquisicao.ativacao);
    this.mensalidade = parseFloat(this.aquisicao.mensalidade);
  },
  components: {
    CustomAlert,
  },
  methods: {
    async finalizarForm() {
      this.$refs.button.disabled = true;
      let response = await axios.post("/credenciado/formulario4", {
        id: this.id,
      });
      if (response.status == 200) {
        Vue.$toast.open({
          message: "Seu pedido foi salvo com sucesso",
          type: "success",
          position: "bottom-right",
        });
        this.$router.push(`/form5/${hashids.encode(this.id)}`);
      } else {
        Vue.$toast.open({
          message: "Ocorreu um erro",
          type: "error",
          position: "bottom-right",
        });
      }
    },
  },
  data() {
    return {
      id: "",
      aquisicao: "",
      portabilidades: [],
      linhas_telefonicas: [],
      servicos_adicionais: [],
      ativacao: "",
      mensalidade: "",
    };
  },
};
</script>