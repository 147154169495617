<template>
  <div>
    <div class="col-md-12 mx-auto mb-3">
      <form @submit.prevent="finalizarPagina">
        <div class="card">
          <div class="card-body">
            <div class="form-row">
              <div
                style="
                  text-align: left;
                  font-size: 1.5em;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #25215e;
                "
                class="col-md-12"
              >
                <span
                  >Por último, precisamos saber se você precisará de
                  equipamentos e serviços adicionais:</span
                >
              </div>
              <div>
                <p>
                  Segue abaixo uma lista com opções de compra e/ou locação. Os
                  valores estão sujeitos a alteração conforme disponibilidade
                  dos fornecedores.
                </p>
              </div>

              <div
                style="
                  text-align: left;
                  font-size: 1.5em;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #25215e;
                "
                class="col-md-12"
              >
                <span>Aparelho Telefônico – IP (Locação)</span>
              </div>
              
              <Aviso2 />

              <div class="col-md-12 mb-3">
                <ul
                  class="list-group"
                  v-for="(produto, indice) in filteredProdutos"
                  :key="produto.id"
                >
                  <li class="list-group-item" v-if="produto.id != 6">
                    {{ produto.nome }} - <strong>Valor Mensal:</strong> R${{
                      parseFloat(produto.valor_mensal).toFixed(2)
                    }}
                    <input
                      type="number"
                      min="0"
                      @change="
                        adicionarMensal(
                          indice,
                          produto.valor_mensal,
                          produto.id,
                          $event
                        )
                      "
                    />
                  </li>
                </ul>
              </div>

              <div
                style="
                  text-align: left;
                  font-size: 1.5em;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #25215e;
                "
                class="col-md-12"
              >
                <span>Aparelho Telefônico – IP (Compra)</span>
              </div>

              <Aviso2 />

              <div class="col-md-12 mb-2">
                <ul
                  class="list-group"
                  v-for="(produto, indice) in filteredProdutos"
                  :key="produto.id"
                >
                  <li class="list-group-item">
                    {{ produto.nome }} - <strong>Valor Compra:</strong> R${{
                      parseFloat(produto.valor_compra).toFixed(2)
                    }}
                    <input
                      type="number"
                      min="0"
                      @change="
                        adicionarCompra(
                          indice,
                          produto.valor_compra,
                          produto.id,
                          $event
                        )
                      "
                    />
                  </li>
                </ul>
              </div>

              <div
                style="
                  text-align: left;
                  font-size: 1.5em;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #25215e;
                "
                class="col-md-12"
              >
                <span>SOFTPHONE</span>
              </div>

                <Aviso />

                <div class="col-md-12 mb-2" v-if="produtos.length > 0">
                <ul
                  class="list-group"
                >
                  <li class="list-group-item">
                    {{ produtos[5].nome }} - <strong>Valor Mensal:</strong> R${{
                      parseFloat(produtos[5].valor_mensal).toFixed(2)
                    }}
                    <input
                      type="number"
                      min="0"
                      @change="
                        adicionarMensal(
                          5,
                          produtos[5].valor_mensal,
                          produtos[5].id,
                          $event
                        )
                      "
                    />
                  </li>
                </ul>
              </div>

              <div class="mx-auto mt-3">
                <Tarifa />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-primary" ref="button" type="submit">
              Avançar
            </button>
          </div>
        </div>
      </form>
    </div>

    <CustomAlert
      :ativacao="getTotalProdutosCompra"
      :mensalidade="getTotalProdutosMensal"
    ></CustomAlert>
  </div>
</template>

<script>
import Hashids from "hashids";
const hashids = new Hashids();

import CustomAlert from "./CustomAlert";
import Tarifa from "./Tarifa";
import Aviso from './Aviso'
import Aviso2 from './Aviso2'
         
export default {
  async mounted() {
    this.id = hashids.decode(this.$route.params.id)[0];
    let response = await axios.get("credenciado/getprodutos");
    let produtos = response.data;
    this.produtos = produtos;

    response = await axios.get(`credenciado/getaquisicao/${this.id}`);
    let aquisicao = response.data;
    this.aquisicao = aquisicao;
  },
  computed: {
    getTotalProdutosMensal() {
      let total = 0;
      total = this.produtos_mensal.reduce((previous, current) => {
        return (previous +=
          parseFloat(current.valor) * parseFloat(current.quantidade));
      }, 0);

      total +=
        this.aquisicao != null
          ? this.aquisicao.mensalidade != null
            ? parseFloat(this.aquisicao.mensalidade)
            : 0
          : 0;

      return total.toFixed(2);
    },
    filteredProdutos() {
      return this.produtos.filter((produto) => produto.id != 6);
    },
    getTotalProdutosCompra() {
      let total = 0;
      total = this.produtos_compra.reduce((previous, current) => {
        return (previous +=
          parseFloat(current.valor) * parseFloat(current.quantidade));
      }, 0);
      total +=
        this.aquisicao != null
          ? this.aquisicao.ativacao != null
            ? parseFloat(this.aquisicao.ativacao)
            : 0
          : 0;
      return total.toFixed(2);
    },
  },
  data() {
    return {
      produtos: [],
      produtos_mensal: [],
      aquisicao: "",
      produtos_compra: [],
      id: "",
    };
  },
  components: {
    CustomAlert,
    Tarifa,
    Aviso,
    Aviso2
  },
  methods: {
    adicionarCompra(indice, valor, id, event) {
      if (event.target.value == "") {
        event.target.value = 0;
      }
      const alreadyExistsProductIndex = this.produtos_compra.findIndex(
        (element) => element.id == id
      );
      if (alreadyExistsProductIndex != -1) {
        this.produtos_compra.splice(alreadyExistsProductIndex, 1, {
          id: id,
          tipo: "compra",
          valor: valor,
          quantidade: event.target.value,
        });
      } else {
        this.produtos_compra.splice(indice, 0, {
          id: id,
          tipo: "compra",
          valor: valor,
          quantidade: event.target.value,
        });
      }
    },
    adicionarMensal(indice, valor, id, event) {
      if (event.target.value == "") {
        event.target.value = 0;
      }
      const alreadyExistsProductIndex = this.produtos_mensal.findIndex(
        (element) => element.id == id
      );
      if (alreadyExistsProductIndex != -1) {
        this.produtos_mensal.splice(alreadyExistsProductIndex, 1, {
          id: id,
          tipo: "mensal",
          valor: valor,
          quantidade: event.target.value,
        });
      } else {
        this.produtos_mensal.splice(indice, 0, {
          id: id,
          tipo: "mensal",
          valor: valor,
          quantidade: event.target.value,
        });
      }
    },
    async finalizarPagina() {
      let response = await axios.post("/credenciado/formulario3", {
        id: this.id,
        produtos_mensal: this.produtos_mensal,
        produtos_compra: this.produtos_compra,
        mensalidade: this.getTotalProdutosMensal,
        ativacao: this.getTotalProdutosCompra,
      });
      if (response.status == 200) {
        this.$refs.button.disabled = true;
        this.$router.push(`/form4/${hashids.encode(this.id)}`);
      } else {
        Vue.$toast.open({
          message: "Ocorreu um erro",
          type: "error",
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

<style scoped>
</style>